import React, { useState } from "react";
import forn from "forn";
import { useActions, useSelector } from "statux";
import axios from "axios";
import styled from "styled-components";

import { Card, Flex, Icon, Select } from "../../components";

const Name = styled.h3`
  font-weight: bold;
`;

const InputForm = styled.form`
  position: relative;
`;

const InputText = styled.input`
  display: block;
  font-size: 14px;
  height: 30px;
  width: 100%;
  ${p =>
    p.error &&
    `
    border-color: red;
    box-shadow: none;

  `}
`;

const ErrorLabel = styled.label`
  display: block;
  font-size: 14px;
  line-height: 18px;
  padding: 2px 0;
  color: red;
`;

const Add = styled.button`
  font-size: 14px;
  padding: 2px 10px;
  border-radius: 20px;
  border: 1px solid ${p => p.theme.blue};
  background: #fff;
  color: #9084fb;
  display: block;
  width: 100%;
  text-align: center;
  margin: 0 0 5px;

  :hover,
  :focus {
    background: ${p => p.theme.blue};
    color: #fff;
    border: 1px solid ${p => p.theme.blue};
  }
`;

const empty = () => [
  { name: "id", type: "string", access: "read" },
  { name: "created", type: "string", access: "read" },
  { name: "updated", type: "string", access: "read" },
  { type: "string", access: "write" }
];

const validateField = value => {
  if (!value) return;
  if (!/^[a-zA-Z][a-zA-Z0-9_]*$/.test(value)) return "Only alphanumeric";
  if (!/^[a-z][a-z0-9_]*$/.test(value)) return "Only lowercase";
  if (!/^[a-z]/.test(value)) return "Must start with a letter";
  if (value.length > 20) return "Max 20 characters";
};

// Create a new model when this form is submitted
const CreateModel = ({ add, focus }) => (
  <Card as="form" onSubmit={forn(add, { reset: true })}>
    <Flex tight one>
      <InputText
        name="model"
        placeholder="Model name"
        required
        autoFocus={focus}
      />
      <Add>+ Create Model</Add>
    </Flex>
  </Card>
);

const Input = ({ onChange, validate, ...props }) => {
  const [error, setError] = useState(false);
  const onInputChange = e => {
    const value = e.target.value;
    onChange(e.target.value);
    if (!validate) return setError(false);
    return setError(validate(value));
  };
  return (
    <>
      <InputText error={error} onChange={onInputChange} {...props} />
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </>
  );
};

const FieldInput = ({ name, type, setName, add, focus, models, setType }) => (
  <InputForm onSubmit={forn(add)}>
    <Input
      value={name || ""}
      onChange={setName}
      placeholder="Field Name"
      autoFocus={focus}
      validate={validateField}
    />
    <Select value={type} onChange={setType} models={models} />
  </InputForm>
);

const EditModel = ({ model, models, fields = [], setFields }) => (
  <Card>
    <Flex tight one>
      <Name>{model}</Name>
      <label flex="fifth">
        <InputText readOnly value="id" tabIndex="-1" />
      </label>
      <label flex="two-fifth">
        <InputText readOnly value="created" tabIndex="-1" />
      </label>
      <label flex="two-fifth">
        <InputText readOnly value="updated" tabIndex="-1" />
      </label>
      {fields.map((field, i) => {
        const setField = value => {
          setFields(fields.map((f, j) => (i === j ? { ...f, ...value } : f)));
        };
        const setName = name => setField({ name });
        const setType = type => setField({ type });
        if (field.access === "read") {
          return null;
        }
        if (field.focus) {
          setTimeout(() => {
            field.focus = false;
          }, 100);
        }
        return (
          <FieldInput
            {...field}
            key={"index-" + i}
            setName={setName}
            setType={setType}
            models={models}
            add={() =>
              setFields([
                ...fields,
                { type: "string", focus: true, access: "write" }
              ])
            }
          />
        );
      })}
      <Add
        onClick={e => {
          setFields([
            ...fields,
            { type: "string", focus: true, access: "write" }
          ]);
        }}
      >
        + Add Field
      </Add>
    </Flex>
  </Card>
);

export default ({ onSave }) => {
  const id = useSelector("api.id");
  const schema = useSelector("api.schema");
  const [state, setState] = useState(schema);
  const setApi = useActions("api");

  return (
    <Flex one two-600 three-800>
      <Flex flex="full" space>
        <h2>Database</h2>
        <Add
          onClick={async e => {
            const data = {};
            for (let key in state) {
              data[key] = [];
            }
            const { data: res } = await axios.patch(`/${id}/`, {
              schema: state,
              data
            });
            setApi(res);
            onSave();
          }}
        >
          <Icon name="save" /> Save
        </Add>
      </Flex>
      {Object.keys(state).map(name => (
        <EditModel
          key={name}
          model={name}
          models={Object.keys(state)}
          fields={state[name]}
          setFields={fields => setState({ ...state, [name]: fields })}
        />
      ))}
      <CreateModel
        focus={!Object.keys(state).length}
        add={({ model }) => setState({ ...state, [model]: empty() })}
      />
    </Flex>
  );
};
