// Note:
// _NO_XX: for whenever the user is supposed to provide the field but didn't
// _NOT_FOUND: the field was provided, but does not belong to the collection

import slug from "@sindresorhus/slugify";

const errors = {
  METHOD_NOT_AVAILABLE: ({ method = "" }) =>
    `The method '${method.toUpperCase()}' is not available.`,
  NOT_FOUND: ({ thing }) => `Could not find '${thing}'`,
  COLLECTION_NOT_FOUND: ({ model }) =>
    `The collection '/${model}' does not exist in your database`,
  EMPTY_BODY: ({ method }) =>
    `The method "${method}" requires a body to be sent`,
  BODY_NO_OBJECT: () => `The body must be a JSON object`,
  NO_BODY: ({ method }) => `The method '${method}' expects no body to be sent`,
  KEY_NOT_FOUND: ({ key, valid }) =>
    `Invalid key '${key}', these are the valid ones: ${valid
      .map(k => `'${k}'`)
      .join(", ")}`,
  INVALID_TYPE: ({ key, type, found, value }) =>
    `'${key}' must be a ${type}, but ${value} (${found}) received`,
  GET_NOT_FOUND: ({ model, id }) => `The item '/${model}/${id}' was not found`,
  POST_URL_ID: ({ model, id }) =>
    `Please use '/${model}' instead of '/${model}/${id}' to create a new entry`,
  POST_BODY_ID: () => `Cannot set 'id' manually on the POST request body`,
  POST_BODY_CREATED: () =>
    `Cannot set 'created' manually on the POST request body`,
  POST_BODY_UPDATED: () =>
    `Cannot set 'updated' manually on the POST request body`,
  PUT_URL_NO_ID: ({ model }) =>
    `Provide the ID of the resource to modify: '/${model}' => '/${model}/:id'`,
  PUT_NOT_FOUND: ({ model, id }) =>
    `Couldn't find any resource in '/${model}/${id}'`,
  PUT_ID_MISMATCH: ({ model, id, id2 }) =>
    `The resource to modify '/${model}/${id}' does not match the body 'id': { id: '${id2}' }!`,
  PATCH_URL_NO_ID: ({ model }) =>
    `Provide the ID of the resource to modify: '/${model}' => '/${model}/:id'`,
  PATCH_NOT_FOUND: ({ model, id }) =>
    `Couldn't find any resource in '/${model}/${id}'`,
  PATCH_ID_MISMATCH: ({ model, id, id2 }) =>
    `The resource to modify '/${model}/${id}' does not match the body 'id': { id: '${id2}' }!`,
  DELETE_URL_NO_ID: ({ model }) =>
    `Provide the ID of the resource to delete: '/${model}' => '/${model}/:id'`,
  DELETE_NOT_FOUND: ({ model, id }) =>
    `Couldn't find any resource in '/${model}/${id}'`
};

export default id => {
  const fullErrors = {};
  for (let code in errors) {
    fullErrors[code] = (...args) => ({
      error: {
        code,
        message: errors[code](...args),
        details: `https://faster.rest/${id}/docs/#${slug(code)}`
      }
    });
  }
  return fullErrors;
};
