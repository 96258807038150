import React from "react";
import { useSelector } from "statux";

import { Button } from "../../components";
import openApi from "./openApi";

export default () => {
  const api = useSelector("api");
  const onClick = e => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(openApi(api))
    );
    element.setAttribute("download", `${api.id}.yml`);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  return (
    <Button primary icon="save_alt" onClick={onClick}>
      Open API 3.0
    </Button>
  );
};
