import { singular } from "pluralize";

// Generates an Open API file in YML
// TODO: generate it in JSON and then convert it to yaml with a library
const buildType = (type, name) => {
  if (name === "id") {
    return `$ref: '#/components/schemas/Id'`;
  }
  if (name === "created" || name === "updated") {
    return `$ref: '#/components/schemas/Date'`;
  }
  if (/^\{/.test(type)) {
    return `$ref: '#/components/schemas/${type.slice(1, -1)}/properties/id'`;
  }
  if (/^\[/.test(type)) {
    return `type: array
          items:
            $ref: '#/components/schemas/${type.slice(1, -1)}/properties/id'`;
  }
  return `type: ${type}`;
};

const createComponent = api => ([name, props]) => `${name}:
      type: object
      properties:
        ${props
          .map(
            ({ name, type }) => `${name}:
          ${buildType(type, name)}`
          )
          .join("\n        ")}
`;

export default api => `openapi: 3.0.0
info:
  title: API ${api.id}
  description: A full REST API definition for ${api.id}
  version: "1.0.0"
servers:
  - description: Faster Rest endpoint
    url: https://api.faster.rest/${api.id}/

tags:
  ${Object.keys(api.schema)
    .map(
      name => `- name: ${name}
    description: Everything about your ${name}`
    )
    .join("\n  ")}

paths:
  ${Object.entries(api.schema)
    .map(
      ([name, schema]) => `/${name}:
    get:
      summary: Returns a list of ${name}.
      description: Returns the list of all the ${name} in the database.
      tags:
        - ${name}
      responses:
        200:
          description: An array of ${name}
          content:
            application/json:
              schema:
                type: array
                items:
                  $ref: '#/components/schemas/${name}'

    post:
      summary: Creates a new ${singular(name)}.
      description: Insert a new ${name} to the database.
      tags:
        - ${name}
      requestBody:
        required: true
        content:
          application/json:
            schema:
              type: object
              properties:
                ${schema
                  .filter(
                    ({ name }) => !["id", "created", "updated"].includes(name)
                  )
                  .map(
                    ({ name, type }) => `${name}:
                  ${buildType(type, name)}`
                  )
                  .join("\n                ")}

      responses:
        201:
          description: Created

  /${name}/{id}:
    get:
      summary: Returns a single ${singular(name)}.
      description: Returns an item from the database ${name} as an object.
      tags:
        - ${name}
      parameters:
        - in: path
          name: id
          schema:
            $ref: '#/components/schemas/Id'
          required: true
          description: Id of the ${singular(name)} to get
      responses:
        200:
          description: A single ${singular(name)}
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/${name}'

    put:
      summary: Updates a single ${singular(name)}.
      description: Fully replaces all of the fields from the passed ones.
      tags:
        - ${name}
      parameters:
        - in: path
          name: id
          schema:
            $ref: '#/components/schemas/Id'
          required: true
          description: Id of the ${singular(name)} to update
      requestBody:
        required: true
        content:
          application/json:
            schema:
              type: object
              properties:
                ${schema
                  .filter(
                    ({ name }) => !["id", "created", "updated"].includes(name)
                  )
                  .map(
                    ({ name, type }) => `${name}:
                  ${buildType(type, name)}`
                  )
                  .join("\n                ")}
      responses:
        200:
          description: A single ${singular(name)} book that matches the id.
          content:
            application/json:
              schema:
                type: object
                properties:
                  ${schema
                    .filter(
                      ({ name }) => !["id", "created", "updated"].includes(name)
                    )
                    .map(
                      ({ name, type }) => `${name}:
                    ${buildType(type, name)}`
                    )
                    .join("\n                  ")}

    patch:
      summary: Partially update the ${singular(name)}.
      description: Updates a single ${singular(name)} with the data passed.
      tags:
        - ${name}
      parameters:
        - in: path
          name: id
          schema:
            $ref: '#/components/schemas/Id'
          required: true
          description: Id of the ${singular(name)} to update
      requestBody:
        required: true
        content:
          application/json:
            schema:
              type: object
              properties:
                ${schema
                  .filter(
                    ({ name }) => !["id", "created", "updated"].includes(name)
                  )
                  .map(
                    ({ name, type }) => `${name}:
                  ${buildType(type, name)}`
                  )
                  .join("\n                ")}
      responses:
        200:
          description: A single ${singular(name)} book that matches the id.
          content:
            application/json:
              schema:
                type: object
                properties:
                  ${schema
                    .filter(
                      ({ name }) => !["id", "created", "updated"].includes(name)
                    )
                    .map(
                      ({ name, type }) => `${name}:
                    ${buildType(type, name)}`
                    )
                    .join("\n                  ")}

    delete:
      summary: Remove a single ${singular(name)} by its id.
      description: Deletes an ${singular(name)} from the database forever.
      tags:
        - ${name}
      parameters:
        - in: path
          name: id
          schema:
            $ref: '#/components/schemas/Id'
          required: true
          description: Id of the ${singular(name)} to update
      responses:
        200:
          description: Returns the deleted item
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/${name}'
    `
    )
    .join("\n  ")}

components:
  schemas:
    ${Object.entries(api.schema)
      .map(createComponent(api))
      .join("\n    ")}

    Id:
      type: string
      pattern: '^[a-zA-Z0-9]{16}$'

    Date:
      type: string
      format: 'date-time'`;
