import React, { useState } from "react";
import forn from "forn";
import styled from "styled-components";
import { singular } from "pluralize";
import { useSelector } from "statux";

import { Button, Modal, Space } from "../../components";

import SelectOne from "./SelectOne";
import SelectMany from "./SelectMany";

const auto = ["id", "created", "updated"];

const Label = styled.label`
  display: flex;
  margin-bottom: -1px;

  & > div {
    padding: 0 10px;
    border-radius: 0;
    background: #eee;
    line-height: 38px;
    height: 40px;
    flex-grow: 1;
    border: 1px solid #ccc;
    border-right: none;
    white-space: nowrap;
  }

  input {
    flex-basis: 70%;
    line-height: 38px;
    height: 40px;
    border-radius: 0;
    &:focus {
      z-index: 1;
    }
  }

  .react-select__control {
    height: 40;
    min-height: 40;
    border-radius: 0;
    border: 1px solid #ccc;
    box-shadow: none;
    &:hover,
    &:focus,
    &:active {
      border: 1px solid #ccc;
      box-shadow: none;
    }
  }

  .react-select__value-container {
    height: 38px;
    padding: 0 10px;
    position: relative;
  }

  .react-select__placeholder {
    display: none;
  }

  input {
    transition: none;
  }

  .react-select__input input {
    height: 38px;
  }

  .react-select__value-container > * {
    margin: 0 2px;
    padding: 0;
  }

  .react-select__single-value {
    height: 38px;
    line-height: 38px;
  }

  &:first-child {
    div {
      border-radius: 4px 0 0 0;
    }
    input {
      border-radius: 0 4px 0 0;
    }
  }

  &:last-child {
    div {
      border-radius: 0 0 0 4px;
    }
    input {
      border-radius: 0 0 4px 0;
    }
  }
`;

const Footer = styled.footer`
  display: flex;
  padding: 0 !important;
  margin-top: ${p => p.theme.space}px;

  ${Button} {
    margin-bottom: 0;
  }
`;

const Input = ({ name, ...props }) => (
  <Label>
    <div>{name}</div> <input name={name} {...props} />
  </Label>
);

const DeleteButton = ({ data, onDelete }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      delete
      loading={loading}
      onClick={async e => {
        e.preventDefault();
        if (!window.confirm("Are you sure?")) return;
        setLoading(true);
        await onDelete(data);
        setLoading(false);
      }}
    >
      Delete
    </Button>
  );
};

const Field = ({ name, model, placeholder, value }) => {
  const type = useSelector(state => {
    return state.api.schema[model].find(f => f.name === name).type;
  });
  if (/^\{.+\}$/.test(type)) {
    const ref = type.slice(1, -1);
    return <SelectOne name={name} value={value} model={ref} />;
  }
  if (/^\[.+\]$/.test(type)) {
    const ref = type.slice(1, -1);
    return <SelectMany name={name} value={value} model={ref} />;
  }
  return <Input name={name} placeholder={placeholder} defaultValue={value} />;
};

export default ({
  id = "insert",
  api,
  model,
  schema = [],
  data = {},
  onSubmit,
  onDelete
}) => {
  const [loading, setLoading] = useState(false);
  const title = `${Object.values(data).length ? "Edit" : "Add new"} ${singular(
    model
  )}`;
  const onSubmitForm = forn(
    async ({ id, created, updated, ...body }) => {
      setLoading(true);
      await onSubmit({ id: data.id, ...body });
      setLoading(false);
      window.closeAll();
    },
    { reset: true }
  );
  return (
    <Modal id={`id-${id}`} title={title}>
      <form onSubmit={onSubmitForm}>
        <div>
          <Input name="id" defaultValue={data.id || "-"} readOnly />
          {schema[model]
            .filter(({ name }) => !auto.includes(name))
            .map(({ name }) => (
              <Field
                key={name}
                name={name}
                model={model}
                placeholder={`${name} for ${singular(model)}`}
                value={data[name]}
              />
            ))}
          <Input
            name="created"
            defaultValue={data.created || new Date().toISOString()}
            readOnly
          />
          <Input
            name="updated"
            defaultValue={data.updated || new Date().toISOString()}
            readOnly
          />
        </div>
        <Footer>
          <Button primary loading={loading}>
            {Object.values(data).length ? "Update" : "Add"}
          </Button>
          {onDelete && (
            <>
              <Space />
              <DeleteButton data={data} onDelete={onDelete} />
            </>
          )}
          <Space grow />
          <Button htmlFor={`id-${id}`}>Cancel</Button>
        </Footer>
      </form>
    </Modal>
  );
};
