import React from "react";
import styled from "styled-components";
import ReactGA from "react-ga";

import Icon from "./Icon";
import Flex from "./Flex";

const Modal = styled.div`
  .overlay ~ * {
    // Otherwise it glitches
    z-index: 10000000;

    // Make it non-clickable when hidden
    pointer-events: none;
  }
  input:checked ~ .overlay ~ * {
    pointer-events: all;
    overflow: visible;
  }
  .overlay ~ * > :last-child {
    margin-bottom: -20px;
  }
  .overlay ~ * > * {
    width: calc(100% + 20px);
    max-width: none;
  }
`;

const ModalContainer = styled.div`
  min-width: 500px;
  width: 100%;
  max-width: 800px;
  padding: 30px !important;
  border-radius: ${p => p.theme.radius}px!important;
  position: relative;

  @keyframes bubble {
    0% {
      transform: scale(0.2);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.2);
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    transition: all 1s ease;
    opacity: 0;
    pointer-events: none;
  }

  &::before {
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${p => p.theme.radius}px!important;
  }
  &::after {
    background: ${p => p.theme.blue};
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
    border-radius: 50px;
  }

  &.loading {
    overflow: hidden !important;
    &::before {
      opacity: 1;
      pointer-events: all;
    }
    &::after {
      opacity: 1;
      animation: bubble infinite 1s;
    }
  }
`;

const Header = styled.div`
  display: flex !important;
  justify-content: space-between;
`;

const Close = styled.label`
  height: 40px;
  width: 40px;
  line-height: 20px;
  padding: 10px;
  margin: -10px;
  text-align: center;
  border-radius: 40px;
  transition: all 0.2s ease;

  i {
    font-size: 20px;
    margin: 0 !important;
    vertical-align: inherit !important;
  }
  &:hover {
    transform: scale(1.2);
    // color: red;
    background: #eee;
  }
`;

export default ({ id, title, track, loading, children }) => (
  <Modal className="modal">
    <input
      id={id}
      type="checkbox"
      onChange={e => {
        if (!track) return;
        if (e.target.checked) {
          ReactGA.pageview(track);
        } else {
          ReactGA.pageview(window.location.pathname + window.location.search);
        }
      }}
    />
    <label htmlFor={id} className="overlay" />
    <ModalContainer className={loading ? "loading" : ""}>
      <Flex>
        <Header>
          {title && <h2>{title}</h2>}
          <Close htmlFor={id}>
            <Icon name="close" />
          </Close>
        </Header>
        {children}
      </Flex>
    </ModalContainer>
  </Modal>
);
