import React, { useState } from "react";
import forn from "forn";
import styled from "styled-components";
import { Route } from "react-router";
import { useSelector } from "statux";

import { Button, Card, Code, Flex } from "../../components";
import { query, withApi } from "../../utils";
import NoModels from "./NoModels";

const clone = data => JSON.parse(JSON.stringify(data));

const Textarea = styled.textarea`
  min-height: 180px;
  resize: vertical;
`;

const Label = styled.label`
  p {
    margin: 0 0 5px;
  }
`;

const mock = async (form, db) => {
  if (form.body) form.body = JSON.parse(form.body);
  const [model, id] = form.path.split("/").filter(Boolean);
  form.model = model;
  form.id = id;
  const body = await query(clone(db), form);
  const response = { body, status: body.error ? 400 : 200 };
  if (body.error && /NOT_FOUND/.test(body.error.code)) {
    response.status = 404;
  }
  return response;
};

const Simulator = ({ onRequest }) => {
  const [method, setMethod] = useState("get");
  const db = useSelector("api");

  return (
    <Card>
      <form onSubmit={forn(data => mock(data, db).then(onRequest))}>
        <Flex two>
          <Label>
            <p>Method:</p>
            <select
              name="method"
              onChange={e => setMethod(e.target.value)}
              defaultValue={method}
            >
              <option value="get">GET</option>
              <option value="post">POST</option>
              <option value="put">PUT</option>
              <option value="patch">PATCH</option>
              <option value="delete">DELETE</option>
            </select>
          </Label>
          <Label flex="full">
            <p>URL Path:</p>
            <input
              name="path"
              placeholder="/users"
              defaultValue={`/${Object.keys(db.schema)[0]}`}
            />
          </Label>
          {["post", "put", "patch"].includes(method) ? (
            <Label flex="full">
              <div>Data (json):</div>
              <Textarea name="body" />
            </Label>
          ) : null}
          <Button primary>Test</Button>
        </Flex>
      </form>
    </Card>
  );
};

const HowItWorks = () => (
  <Card>
    <h2>How it works</h2>
    <p>
      These requests run against a <strong>read-only</strong> copy of your data
      for testing. They <strong>do not modify</strong> the existing data in any
      way.
    </p>
    <p>
      If you want to modify your data, please use the "Database" section or a
      service like Postman.
    </p>
  </Card>
);

export default withApi(() => {
  const id = useSelector("api.id");
  const hasSchema = useSelector(state => {
    return Object.keys(state.api.schema).length;
  });
  const [response, setResponse] = useState(null);

  if (!hasSchema) return <NoModels />;

  return (
    <section>
      <Flex one two-900 stretch>
        <h1 flex="full">Playground for "{id}"</h1>
        <div>
          <Route path="/" exact>
            <Simulator onRequest={setResponse} />
          </Route>
          <Route path="/:id/test" exact>
            <Flex>
              <Simulator onRequest={setResponse} />
              <HowItWorks />
            </Flex>
          </Route>
        </div>
        {response ? (
          <Code js status={response.status}>
            {JSON.stringify(response.body, null, 2)}
          </Code>
        ) : (
          <Code js>{"// Make a mock request"}</Code>
        )}
      </Flex>
    </section>
  );
});
