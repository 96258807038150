import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;

  @keyframes spin {
    0% {
      tranform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &.loading {
    pointer-events: none;
    opacity: 1;
    overflow: hidden;
    // Otherwise somehow it shiftes other elements
    vertical-align: middle;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.8);
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -10px 0 0 -10px;
      border: 3px solid ${p => p.theme.blue};
      border-right-color: transparent;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      animation: spin 0.6s linear infinite;
    }
  }
`;

const Toggle = styled.div`
  height: auto;
  padding: 0.3em 0.9em;
  background: #eee;
  display: inline-block;
  cursor: pointer;
  transition: box-shadow 0.2s ease;
  text-align: center;
  font-size: 16px;

  &:first-child {
    border-radius: 0.2em 0 0 0.2em;
  }

  &:last-child {
    border-radius: 0 0.2em 0.2em 0;
  }

  &:hover {
    box-shadow: inset 0 0 0 100em rgba(0, 0, 0, 0.08);
  }

  ${p =>
    p.selected &&
    `
    pointer-events: none;
    background: ${p.theme.blue};
    color: #fff;
  `}
`;

export default ({ value, options, loading, onChange }) => (
  <Wrap className={loading ? "loading" : ""}>
    {options.map(key => (
      <Toggle key={key} selected={value === key} onClick={e => onChange(key)}>
        {key}
      </Toggle>
    ))}
  </Wrap>
);
