import useAsyncEffect from "use-async-effect";
import axios from "axios";
import burla from "burla";
import { useActions } from "statux";
import { local } from "brownies";

import { decode } from "../utils";

export default () => {
  const setUser = useActions("user");
  useAsyncEffect(async () => {
    const code = burla.query.code;
    if (!code) return;

    setUser({ loading: true });
    try {
      delete burla.query.code;
      const { data } = await axios.get(`/auth?code=${code}`);
      if (data.token) {
        axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
        const user = decode(data.token);
        local.token = data.token;
        setUser(user);
      } else {
        setUser(false);
      }
    } catch (error) {
      alert(`Error logging in: ${error.message}`);
      setUser(false);
    }
  }, []);
  return null;
};
