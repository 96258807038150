import React from "react";
import styled from "styled-components";

import Link from "./Link";
import Icon from "./Icon";

const Button = styled.button.attrs({ primary: undefined, delete: undefined })`
  &.delete {
    color: red;
    background-color: #fdd;
  }

  position: relative;

  @keyframes spin {
    0% {
      tranform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &.loading {
    pointer-events: none;
    opacity: 1;
    overflow: hidden;
    // Otherwise somehow it shiftes other elements
    vertical-align: middle;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.8);
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -10px 0 0 -10px;
      border: 3px solid ${p => p.theme.blue};
      border-right-color: transparent;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      animation: spin 0.6s linear infinite;
    }
  }
`;

const withStyle = comp => styled(comp)``;

export default withStyle(
  ({ icon, className = "", children, loading, ...props }) => {
    let type = "";
    if (props.delete) {
      type = "delete";
    }
    if (!type && !props.primary) {
      type = "pseudo";
    }

    let as = "button";
    if (props.htmlFor) {
      as = "label";
    }
    if (props.to || props.onClick) {
      as = Link;
    }

    const load = loading ? "loading" : "";
    const classes = `button ${className} ${type} ${load}`;
    return (
      <Button as={as} className={classes} {...props}>
        {icon && <Icon name={icon} />} {children}
      </Button>
    );
  }
);
