import buildFields from "./buildFields";

export default ({ id, name, method, title, entry }) => `
## ${title}

\`\`\`
${method}
\`\`\`

Retrieve a list of all the ${name} from the database. Returns a JSON that is an Array of Objects:

\`\`\`js
const ${name} = await axios.get('/${name}');
console.log(${name});
\`\`\`

\`\`\`js
[
${buildFields(entry, "  ")},
  ...
]
\`\`\`

If the collection is empty it will return an empty array:

\`\`\`js
const ${name} = await axios.get('/${name}');
console.log(${name});
\`\`\`

\`\`\`js
[]
\`\`\`

If the collection is mispelled or non-existing, it will **throw a 404**:

\`\`\`js
// The 404 will throw with Axios
try {
  const ${name} = await axios.get('/${name}ss');
} catch (error) {
  console.log(error);
}
\`\`\`

\`\`\`js
{
  "error": {
    "code": "COLLECTION_NOT_FOUND",
    "message": "The collection '/${name}ss' does not exist in your database",
    "more_info": "https://faster.rest/${id}/errors/COLLECTION_NOT_FOUND"
  }
}
\`\`\`
`;
