import React from "react";
import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  margin-left: -${p => p.theme.space}px;
  margin-bottom: -${p => p.theme.space}px;
  width: calc(100% + ${p => p.theme.space}px);
  max-width: calc(100% + ${p => p.theme.space}px);

  &.center {
    align-items: center;
  }

  &.stretch {
    align-items: stretch;
  }

  & > * {
    padding-left: ${p => p.theme.space}px;
    padding-bottom: ${p => p.theme.space}px;
  }

  &.tight {
    margin-left: -${p => p.theme.space / 2}px;
    margin-bottom: -${p => p.theme.space / 2}px;
    width: calc(100% + ${p => p.theme.space / 2}px);
    max-width: calc(100% + ${p => p.theme.space / 2}px);

    & > * {
      padding-left: ${p => p.theme.space / 2}px;
      padding-bottom: ${p => p.theme.space / 2}px;
    }
  }

  &.left {
    justify-content: flex-start;
    & > * {
      flex-grow: 0;
    }
  }

  &.top {
    align-items: flex-start;
  }

  &.base {
    align-items: baseline;
  }

  &.space {
    justify-content: space-between;
    & > * {
      flex-grow: 0;
    }
  }

  .right {
    text-align: right;
  }
`;

export default ({ as, children, ...props }) => (
  <Flex as={as} className={`flex ${Object.keys(props).join(" ")}`}>
    {React.Children.map(
      children,
      child =>
        child &&
        !/^\s+$/.test(child) && (
          <div className={child.props && child.props.flex}>{child}</div>
        )
    )}
  </Flex>
);
