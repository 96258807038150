import React from "react";
import axios from "axios";
import { useStore } from "statux";

import { parseData, parseFields } from "./helpers.js";
import { Icon, Link } from "../../components";
import Insert from "./Insert";

const arrToObj = (all, { name, ...field }) => ({
  ...all,
  [name]: field
});

const Arrow = ({ icon, children, size, ...props }) => (
  <Link
    className="button"
    onClick={e => icon || e.stopPropagation()}
    {...props}
  >
    <span style={{ verticalAlign: "middle" }}>{children}</span>
    <Icon font-size={size} name={icon || "chevron_right"} />
  </Link>
);

const ContentRow = ({ id, selected, fields, data, onClick }) => (
  <tr id={id} onClick={onClick}>
    {Object.values(fields).map(({ name, display }) => (
      <td key={`${id}-${name}`} id={`${id}-${name}`}>
        <div className="value" data-field={name}>
          {parseData(data[name], display, v => v)}
        </div>
      </td>
    ))}
  </tr>
);

const TableHead = ({ fields }) => (
  <thead>
    <tr>
      {Object.values(fields).map(field => (
        <th key={"head-" + field.name}>{field.header}</th>
      ))}
    </tr>
  </thead>
);

export default ({ id, model, schema, data, selected }) => {
  const [api, setApi] = useStore("api");
  const fields = schema[model]
    .filter(({ name }) => !["created", "updated"].includes(name))
    .map(({ name, type, access }) => {
      if (name === "created" || name === "updated") return null;
      const field = { name, title: name, readonly: access === "read" };
      if (name === "id") {
        field.display = (value = "") => (
          <Arrow icon="launch" size="14px">
            {value.slice(0, 16)}
          </Arrow>
        );
      }
      if (/^\{/.test(type) || /^\[/.test(type)) {
        const table = type.slice(1, -1);
        field.display = (value = "", cb) =>
          value.split(",").map(val => (
            <React.Fragment key={`${id}-${table}`}>
              <Arrow to={`/${id}/data/${table}`}>
                {table}:{val.slice(0, 4)}
              </Arrow>{" "}
            </React.Fragment>
          ));
      }
      return field;
    })
    .reduce(arrToObj, {});

  return (
    <>
      {data.map(item => (
        <Insert
          id={item.id}
          key={item.id}
          api={"abc"}
          model={model}
          schema={schema}
          data={item}
          onSubmit={async data => {
            const response = await axios.put(
              `/${id}/${model}/${data.id}`,
              data
            );
            // Edit the item and replace it
            setApi({
              ...api,
              data: {
                ...api.data,
                [model]: api.data[model].map(item => {
                  if (item.id !== data.id) return item;
                  return response.data;
                })
              }
            });
          }}
          onDelete={async data => {
            await axios.delete(`/${id}/${model}/${data.id}`);
            // Edit the item and replace it
            setApi({
              ...api,
              data: {
                ...api.data,
                [model]: api.data[model].filter(item => {
                  return item.id !== data.id;
                })
              }
            });
          }}
        />
      ))}
      <table className="edittable">
        <TableHead fields={parseFields(fields)} />
        <tbody>
          {data.map(item => (
            <ContentRow
              id={item.id}
              key={item.id}
              selected={selected === item.id}
              fields={parseFields(fields)}
              data={item}
              onClick={e => {
                const it = document.querySelector(`#id-${item.id}`);
                if (!it) return;
                it.checked = true;
              }}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};
