import styled from "styled-components";

export default styled.div`
  margin-bottom: 0;
  border: none;
  border-radius: ${p => p.theme.radius}px;
  display: block;
  padding: 20px;
  color: inherit;
  background: #fff;
  transition: all 0.2s ease;

  // Links, buttons and labels have hoverable status
  a&,
  button&,
  label& {
    box-shadow: ${p => p.theme.shadow};

    :hover {
      box-shadow: ${p => p.theme.darkShadow};
    }

    :hover:active {
      box-shadow: ${p => p.theme.lightShadow};
    }
  }
`;
