import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "statux";
import Select, { components } from "react-select";
import Label from "./Label";

const Value = styled.div`
  font-family: monospace;
  font-size: 14px;
  padding: 3px 10px;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 90%);
  border-radius: 2px;
`;

const SingleValue = ({ data, children, ...props }) => (
  <components.SingleValue {...props}>
    <Value>{data.value}</Value>
  </components.SingleValue>
);

export default ({ name, value, model }) => {
  const data = useSelector(`api.data.${model}`);
  const [prefix, setPrefix] = useState(false);
  const onInputChange = text => {
    const prefix = text.includes(":") ? text.split(":")[0] : false;
    setPrefix(prefix);
  };
  const options = data.map(({ id, ...item }) => {
    if (!prefix || !(prefix in item)) {
      let label = "id:" + id + ",\n";
      for (let key in item) {
        label += key + ":" + item[key] + ",\n";
      }
      return { value: id, label };
    }
    return { value: id, label: `${prefix}:${item[prefix]}` };
  });
  return (
    <Label>
      <div>{name}</div>
      <span style={{ display: "inline-block", width: "70%" }}>
        <Select
          name={name}
          components={{ SingleValue }}
          className="react-select"
          classNamePrefix="react-select"
          onInputChange={onInputChange}
          defaultValue={options.find(opt => opt.value === value)}
          options={options}
          isClearable
        />
      </span>
    </Label>
  );
};
