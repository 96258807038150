export const blue = "#9084fb";
export const darkBlue = "#5749d8";
export const lightBlue = "#eef5ff";

export const green = "#cfc";
export const lightGreen = "#dfd";
export const darkGreen = "#0d0";

export const yellow = "#ffc";
export const darkYellow = "#a80";

export const lightRed = "#fdd";
export const red = "#fdd";
export const darkRed = "#f00";

// Strong short shadow and long tail
export const shadow = "2px 2px 8px -6px rgba(0, 0, 0, 0.3)";
export const lightShadow = "1px 1px 2px -1px rgba(0, 0, 0, 0.4)";
export const darkShadow = "2px 2px 12px -6px rgba(0, 0, 0, 0.4)";

export const radius = 10;
export const space = 20;
