import styled from "styled-components";

export default styled.div`
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 0.2em;
  background: ${p => p.theme.red};
  border: 1px solid ${p => p.theme.darkRed};
  color: ${p => p.theme.darkRed};
`;
