import { useState } from "react";

export default () => {
  const [loading, setLoading] = useState(false);
  const withLoading = fn => async (...args) => {
    setLoading(true);
    try {
      return await fn(...args);
    } finally {
      setLoading(false);
    }
  };
  return [loading, withLoading];
};
