import { singular } from "pluralize";
import buildFields from "./buildFields";

export default ({ id, name, method, title, entry, fields }) =>
  fields.length
    ? `
## ${title}

\`\`\`
${method}
\`\`\`

Modify one or several of the specified ${singular(name)} fields:

\`\`\`js
const id = '${entry.id}';
const ${singular(name)} = await axios.patch(\`/${name}/\${id}\`, {
  ${fields[1].name}: 'xxx'
});
console.log(${singular(name)});
\`\`\`

\`\`\`js
${buildFields({ ...entry, [fields[1].name]: "xxx" })}
\`\`\`

The \`id\`, \`created\` and \`updated\` fields cannot be modified with this method, and attempting to do so will result in failure.
`
    : `This model only has the default fields so it cannot be edited manually.`;
