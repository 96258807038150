import { singular } from "pluralize";
import buildFields from "./buildFields";

const clean = ({ id, created, updated, ...other } = {}) => other;

export default ({ id, name, method, title, fields, entry }) => `
## ${title}

\`\`\`
${method}
\`\`\`


Creates a new instance of ${singular(name)}:

\`\`\`js
// 'id', 'created' and 'updated' are autogenerated
const ${singular(name)} = await axios.post('/${name}', ${buildFields(
  clean(entry)
)});
console.log(${singular(name)});
\`\`\`

\`\`\`js
${buildFields(entry)}
\`\`\`

It accepts an object with these properties:

${fields
  .filter(f => f.access === "write")
  .map(field => `- "${field.name}": ${field.type}`)
  .join("\n")}

It will autogenerate these fields, though you cannot edit them manually:

${fields
  .filter(f => f.access === "read")
  .map(field => `- "${field.name}": ${field.type}`)
  .join("\n")}
`;
