import React from "react";
import styled from "styled-components";
import { Button, Card, Flex, Upgrade } from "../../components";

const Pricing = styled.section`
  h2 {
    margin-top: -100px;
    padding-top: 100px;
  }
`;

const Feat = styled.div`
  margin: 12px 0 0;
`;

const Title = styled.h3`
  font-size: 1.2em;
  margin: 10px 0;
`;

const Price = styled.div`
  display: block;
  margin: ${p => p.theme.space}px 0 0;
  font-weight: bold;
`;

const Space = styled.div`
  flex-grow: 1;
`;

const Plan = styled(Card)`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > button,
  & > .button {
    display: block;
    width: 100%;
    font-weight: bold;
    margin-bottom: 0;
  }
`;

const Feature = ({ name, value }) => (
  <Feat>
    <strong>{value}</strong> {name}
  </Feat>
);

export default () => (
  <Pricing>
    <Flex one two-600 four-900 stretch>
      <div flex="full">
        <h2 id="pricing">Pricing</h2>
        <p>
          Faster Rest is <strong>not VC-funded</strong>, so it is a bit more
          expensive to make sure we stay in business:
        </p>
      </div>
      <Plan>
        <Title>Play</Title>
        <Feature name="APIs" value="Public" />
        <Feature name="storage" value="100kb" />
        <Feature name="transfer" value="1MB" />
        <Feature name="data life" value="24h" />

        <Space />
        <Price>Free</Price>
        <Button
          icon="favorite"
          to="https://www.paypal.me/franciscopresencia/19"
        >
          Donate
        </Button>
      </Plan>
      <Plan>
        <Title>Make</Title>
        <Feature name="APIs" value="Private" />
        <Feature name="storage" value="10MB" />
        <Feature name="transfer" value="100MB" />
        <Feature name="life" value="Unlimited" />
        <Feature name="logs" value="1K" />
        <Space />
        <Price>$10/month</Price>
        <Upgrade plan="make" />
        <Button primary htmlFor="plan_make" icon="payment">
          Buy
        </Button>
      </Plan>
      <Plan>
        <Title>Startup</Title>
        <Feature name="APIs" value="Named " />
        <Feature name="storage" value="100MB" />
        <Feature name="transfer" value="10GB" />
        <Feature name="life" value="Unlimited" />
        <Feature name="logs" value="100K" />
        <Space />
        <Price>$100/month</Price>
        <Upgrade plan="startup" />
        <Button primary htmlFor="plan_startup" icon="payment">
          Buy
        </Button>
      </Plan>
      <Plan>
        <Title>Business</Title>
        <Feature name="APIs" value="Named" />
        <Feature name="storage" value="10GB" />
        <Feature name="transfer" value="1TB" />
        <Feature name="life" value="Unlimited" />
        <Feature name="logs" value="10M" />
        <Space />
        <Price>Contact us</Price>
        <Upgrade plan="business" />
        <Button htmlFor="plan_business" icon="email">
          Email
        </Button>
      </Plan>
    </Flex>
  </Pricing>
);
