import useAsyncEffect from "use-async-effect";
import { useStore } from "statux";
import axios from "axios";

export default id => {
  const [api, setApi] = useStore("api");

  useAsyncEffect(
    async isMounted => {
      // Already loaded or it's currently loading
      if (api.loading) return;

      try {
        const { data } = await axios.get(`/${id}`);
        if (!isMounted()) return;
        setApi(data);
      } catch (error) {
        if (!isMounted()) return;
        setApi({ error: error.message });
      }
    },
    [id]
  );

  if (api.id !== id) return { loading: true };

  return api || {};
};
