import React from "react";
import Mark from "react-markdown";
import slug from "@sindresorhus/slugify";
import styled from "styled-components";

import { Card } from "../../components";
import useHighlight from "../../useHighlight";

export const Title = styled.h1`
  margin-bottom: ${p => p.theme.space}px;
`;

export const SubTitle = styled.h2`
  margin-bottom: ${p => p.theme.space}px;
  padding-top: ${p => p.theme.space * 5}px;
  margin-top: -${p => p.theme.space * 5}px;
`;

const DocsCard = styled(Card)`
  margin-bottom: ${p => p.theme.space * 4}px;
  box-shadow: ${p => p.theme.shadow};
  overflow: hidden;

  h1,
  h2 {
    padding-top: ${p => p.theme.space * 5}px;
    margin-top: -${p => p.theme.space * 5}px;
  }

  pre + pre {
    border-top: 2px solid ${p => p.theme.blue};
    margin-top: -${p => p.theme.space}px;
    position: relative;
    &:after {
      content: "output";
      position: absolute;
      top: 0;
      right: 0;
      padding: 1px 5px 3px 5px;
      background: ${p => p.theme.blue};
      color: #fff;
      border-radius: 0 0 0 4px;
      font-size: 0.8em;
    }
  }
`;

function flatten(text, child) {
  return typeof child === "string"
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text);
}

function heading(props) {
  var children = React.Children.toArray(props.children);
  var text = children.reduce(flatten, "");
  const id = slug(text);
  return React.createElement("h" + props.level, { id }, props.children);
}

export const Markdown = ({ children }) => {
  useHighlight(children);
  return (
    <DocsCard>
      <Mark source={children} renderers={{ heading }} />
    </DocsCard>
  );
};
