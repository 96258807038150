import React from "react";
import styled from "styled-components";

import {
  Button,
  Card,
  Create,
  Flex,
  Github,
  Link,
  Tweet,
  Subscribe
} from "../../components";
import Pricing from "./Pricing";

import Test from "../Test";

const Home = styled.div`
  section {
    margin: 60px 0;
  }

  p {
    margin: 5px 0;
    padding: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .center {
    display: flex;
    align-items: center;
  }

  img {
    margin: 30px auto 0;
    display: block;
    height: 300px;
    max-width: 90%;
  }

  img.smaller {
    min-height: 0;
    width: 230px;
    margin: 0 auto;
    display: block;
  }
`;

export default () => (
  <Home>
    <Flex center one two-600>
      <div>
        <h1>Faster Rest</h1>
        <p>
          Create well documented APIs and DBs in seconds. Made for productive
          makers:
        </p>
        <Create /> <Button to="/demo">Demo</Button>
        <Subscribe />
      </div>
      <img src="/img/chat.svg" alt="" />
    </Flex>

    <section>
      <Flex one three-900>
        <Card>
          <h3>Well documented</h3>
          <p>Everything is explained in detail with many examples</p>
        </Card>
        <Card>
          <h3>Easy to build</h3>
          <p>Design new APIs in seconds with just a few clicks</p>
        </Card>
        <Card>
          <h3>Sleep tight</h3>
          <p>Alerts and hourly backups to reduce your business risks</p>
        </Card>
      </Flex>
    </section>

    <Test base="https://api.faster.rest/demo" id="demo" />

    <section>
      <Flex one three-900>
        <div flex="full">
          <h2>New features</h2>
          <p>
            We are considering these, let us know what's important to you on
            Twitter:
          </p>
        </div>
        <Card>
          <h3>
            <Tweet>
              Hey @faster_rest, I'm interested on #graphql for
              https://faster.rest/
            </Tweet>
            GraphQL
          </h3>
          <p>Built on your models for querying nested data</p>
        </Card>
        <Card>
          <h3>
            <Tweet>
              Hey @faster_rest, I'm interested on #fileupload for
              https://faster.rest/
            </Tweet>
            File Upload
          </h3>
          <p>Asset storage fully integrated with your other requests</p>
        </Card>
        <Card>
          <h3>
            <Tweet>
              Hey @faster_rest, I'm interested on #auth for https://faster.rest/
            </Tweet>
            Login system
          </h3>
          <p>Seamless password and social network login</p>
        </Card>
      </Flex>
    </section>

    <section>
      <Flex center one two-600>
        <div>
          <h2>Documentation made right</h2>
          <p>
            You can learn all the requests and parameters accepted with the
            detailed documentation:
          </p>
          <Button primary to="/demo/docs">
            See Demo Docs
          </Button>
        </div>
        <img src="/img/web_developer.svg" alt="" />
      </Flex>
    </section>

    <section>
      <Flex one three-900>
        <div flex="full">
          <h2>Support Open Source</h2>
          <p>
            Published several libraries while making Faster Rest (among{" "}
            <Link to="https://github.com/franciscop/">
              others from the author
            </Link>
            ):
          </p>
        </div>
        <Card>
          <h3>
            <Github repo="franciscop/statux" /> statux
          </h3>
          <p>State management for React with easy Hooks</p>
        </Card>
        <Card>
          <h3>
            <Github repo="franciscop/happy" /> happy
          </h3>
          <p>Simplify your day-to-day git workflow</p>
        </Card>
        <Card>
          <h3>
            <Github repo="franciscop/burla" /> burla
          </h3>
          <p>URL manipulation library using the History API</p>
        </Card>
      </Flex>
    </section>

    <Pricing />
  </Home>
);
