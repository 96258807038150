import React from "react";

import styled from "styled-components";

const Vote = styled.a`
  float: right;
  display: inline;
  border: 1px solid #9084fb;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 12px;
  margin: -3px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: monospace;
  font-weight: normal;

  &:hover {
    color: #fff;
    background: #9084fb;
  }
`;

export default ({ children }) => {
  const text = encodeURIComponent(children);
  const href = `https://twitter.com/intent/tweet?text=${text}`;
  return (
    <Vote href={href} target="_blank" rel="noopener noreferrer">
      vote
    </Vote>
  );
};
