import React, { useState } from "react";
import axios from "axios";
import { useSelector, useStore } from "statux";

import { Button, Space } from "../../components";

const ClearButton = () => {
  const [api, setApi] = useStore("api");
  const [loading, setLoading] = useState(false);

  const onClear = async () => {
    if (!window.confirm("Delete ALL data?")) {
      return;
    }
    setLoading(true);
    const data = {};
    Object.keys(api.data).forEach(key => {
      data[key] = [];
    });
    await axios.patch(`/${api.id}`, { data });
    setApi({ ...api, data });
    setLoading(false);
  };

  return (
    <Button delete onClick={onClear} loading={loading}>
      Clear Data
    </Button>
  );
};

const DeleteButton = () => {
  const api = useSelector("api");
  const [loading, setLoading] = useState(false);

  const onDelete = async api => {
    if (!window.confirm("Remove API and Data completely?")) {
      return;
    }
    setLoading(true);
    // Nothing to do here with the answer
    await axios.delete(`/${api.id}`);
    window.location = "/";
    setLoading(false);
  };

  return (
    <Button delete onClick={e => onDelete(api)} loading={loading}>
      Delete API
    </Button>
  );
};

export default () => (
  <div>
    <ClearButton />
    <Space />
    <DeleteButton />
  </div>
);
