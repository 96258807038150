const dev = "development";
const prod = "production";
export const env = process.env.NODE_ENV || dev;
const pick = option => option[env];

export const api = pick({
  [dev]: "http://localhost:3000/",
  [prod]: "https://api.faster.rest/"
});

export const client_id = pick({
  [dev]: "a96b00d8db1aabe52a7a",
  [prod]: "ca0ed722fa1f1cfc8283"
});

export const analytics = pick({
  [dev]: null,
  [prod]: "UA-63739359-6"
});
