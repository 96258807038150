import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import axios from "axios";
import { local } from "brownies";
import * as Sentry from "@sentry/browser";

import "./index.scss";
import App from "./App";
import { analytics, api } from "./config";

axios.defaults.baseURL = api;
if (local.token) {
  axios.defaults.headers.common.Authorization = `Bearer ${local.token}`;
}

ReactGA.initialize(analytics, {
  gaOptions: {
    storage: "none",
    storeGac: false
  }
});

Sentry.init({
  dsn: "https://98df37bfefad4b358f74704582b97ce8@sentry.io/2968284"
});

ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(<App />, document.getElementById("root"));
