import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useActions, useSelector } from "statux";
import { nanoid, create } from "../utils";

export default withRouter(({ history }) => {
  const id = nanoid();
  const setApi = useActions("api");
  const user = useSelector("user");
  return (
    <Link
      to={`/${id}`}
      onClick={e => setApi(create(id, user))}
      className="button"
    >
      New API
    </Link>
  );
});
