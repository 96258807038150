import React from "react";
import styled from "styled-components";
import { singular } from "pluralize";
import { Button } from "../../components";

const Missing = styled.div`
  text-align: center;
  margin: 30px 0 80px;
`;

export default ({ name, onClick }) => (
  <Missing>
    <p>There are no {name} yet</p>
    <Button primary flex="nogrow" icon="add" htmlFor="id-insert">
      Add first {singular(name)}
    </Button>
  </Missing>
);
