import styled from "styled-components";
import { Link } from "react-router-dom";
import { Card } from "../../components";

export default styled(Card).attrs({ as: Link })`
  height: 50px;
  line-height: 50px;
  padding: 0 20px;

  .label {
    font-size: 14px;
    background: #eee;
    color: inherit;
  }
`;
