export default ({ id, title, method }) => `
## ${title}

These APIs cannot be made secure with only a front-end, please [make sure you understand the security model]() before publishing your app to users.

This documentation considers three situations:

1. **All public** for dev and early testing.
2. **Filtered** data and endpoints on a backend to treat a portion of the data as public.
3. **Login system** and data access controls in a backend.

For the first point you don't need a token. For the other two (and the first if you want) you set the API to \`private\` and generate a token in the [API settings](/${id}/settings).

Then when we are with axios we need to add this token to the headers. In combination with the previous init code:

\`\`\`js
import axios from 'axios';
const AUTH = 'YOUR_TOKEN';

axios.defaults.baseURL = 'https://api.faster.rest/${id}';
axios.defaults.headers.common.Authorization = \`Bearer \${AUTH}\`;
\`\`\`

If you are using the environment variables, the API initialization code will look like this:

\`\`\`js
import axios from 'axios';
axios.defaults.baseURL = process.env.ENDPOINT;
axios.defaults.headers.common.Authorization = \`Bearer \${process.env.AUTH}\`;
\`\`\`


`;
