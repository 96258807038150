import Store from "statux";
import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Auth, Nav, Warning, ScrollTop } from "./components";
import { ThemeProvider } from "styled-components";
import { local } from "brownies";

import { decode } from "./utils";
import * as theme from "./theme";
import * as Pages from "./pages";

const user = local.token ? decode(local.token) : null;

export default () => (
  <ThemeProvider theme={theme}>
    <Toaster />
    <Store user={user} api={{}}>
      <BrowserRouter>
        <Route component={ScrollTop} />
        <Warning />
        <Auth />
        <Nav />
        <Route path="/:id?/:part?/:model?" component={Nav} />
        <Switch>
          <Route exact path="/" component={Pages.Home} />
          <Route exact path="/user" component={Pages.User} />
          <Route exact path="/:id" component={Pages.Dashboard} />
          <Route exact path="/:id/settings" component={Pages.Settings} />
          <Route exact path="/:id/logs" component={Pages.Logs} />
          <Route exact path="/:id/docs" component={Pages.Docs} />
          <Route exact path="/:id/test" component={Pages.Test} />
          <Route exact path="/:id/data/:model" component={Pages.Data} />
          <Route exact path="/:id/data/:model/:dataid" component={Pages.Data} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </Store>
  </ThemeProvider>
);
