import { singular } from "pluralize";

export default ({ id, name, method, title, entry, fields }) => `
## ${title}

\`\`\`
${method}
\`\`\`

Deletes a single ${singular(name)}. Does not return anything:

\`\`\`js
const id = '${entry.id}';
await axios.delete(\`/${name}/\${id}\`);
\`\`\`
`;
