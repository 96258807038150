import buildFields from "./buildFields";

export default ({ id, name, method, title, entry, fields }) =>
  fields.length
    ? `
## ${title}

\`\`\`
${method}
\`\`\`

Change one instance from ${name} for a new full instance. Returns the updated item:

\`\`\`js
const id = '${entry.id}';
const prev = await axios.get(\`/${name}/\${id}\`);
const ${name} = await axios.put(\`/${name}/\${id}\`, {
  ...prev,
  ${fields[1].name}: 'xxx'
});
console.log(${name});
\`\`\`

\`\`\`js
${buildFields({ ...entry, [fields[1].name]: "xxx" })}
\`\`\`

The \`id\`, \`created\` and \`updated\` fields cannot be modified with this method, and attempting to do so will result in failure.
`
    : `This model only has the default fields so it cannot be edited manually.`;
