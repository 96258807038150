import React from "react";

import Button from "./Button";
import Modal from "./Modal";
import Space from "./Space";

export default ({ plan }) => (
  <Modal id={`plan_${plan}`} title="Working on it" track={`/buy/${plan}`}>
    <p>
      Woah sure
      <i>
        <strong> you </strong>
      </i>
      are fast! We are preparing the "{plan}" plan and paid accounts, want to
      subscribe for updates? You can also donate any time if you like what we're
      doing:
    </p>
    <div>
      <Button primary to="https://eepurl.com/gutZEj" icon="mail_outline">
        Subscribe
      </Button>
      <Space />
      <Button to="https://www.paypal.me/franciscopresencia/19" icon="favorite">
        Donate
      </Button>
    </div>
  </Modal>
);
