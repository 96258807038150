import React, { useState } from "react";
import { useSelector } from "statux";
import { Route, useParams } from "react-router";
import styled from "styled-components";
import burla from "burla";

import { client_id } from "../config";
import Button from "./Button";
import Create from "./Create";
import Icon from "./Icon";
import Link from "./Link";

const Logo = styled.img`
  width: 24px;
  height: 24px;
  margin: -2px 4px 0 0;
`;

const LoginButton = () => {
  const user = useSelector("user");
  const [loading, setLoading] = useState(Boolean(burla.query.code));
  if (loading && !user) {
    return <Button loading>Login</Button>;
  }
  return user ? (
    <Button to="/user" loading={user.loading}>
      {user.name || "User"}
    </Button>
  ) : (
    <Button
      width={10}
      href={`https://github.com/login/oauth/authorize?client_id=${client_id}&scope=user:email`}
      onClick={e => setLoading(true)}
    >
      Login
    </Button>
  );
};

const BreadCrumbs = () => {
  const { id, part, model } = useParams();
  if (!id) return null;
  return (
    <>
      <Icon name="arrow_forward_ios" />
      <Link exact to={`/${id}`}>
        {id.slice(0, 4)}
      </Link>
      {part &&
        (model ? (
          <>
            <Icon name="arrow_forward_ios" />
            <Link exact to={`/${id}/${part}/${model}`}>
              {model}
            </Link>
          </>
        ) : (
          <>
            {" "}
            <Icon name="arrow_forward_ios" />{" "}
            <Link exact to={`/${id}/${part}`}>
              {part}
            </Link>
          </>
        ))}
    </>
  );
};

export default () => (
  <nav>
    <div className="brand">
      <Link exact to="/">
        <Logo src="/logo.png" alt="Logo" />
        Faster
      </Link>
      <Route path="/:id?/:part?/:model?" component={BreadCrumbs} />
    </div>

    <input id="menu" type="checkbox" className="show" />
    <label htmlFor="menu" className="burger pseudo button">
      <Icon name="menu" />
    </label>

    <div className="menu">
      <LoginButton />
      <Button to="/demo">Demo</Button>
      <Button to="#pricing">Pricing</Button>
      <Create />
    </div>
  </nav>
);
