import { singular } from "pluralize";
import errors from "../../utils/errors";

const descriptions = {
  METHOD_NOT_AVAILABLE: `Tried to call the API with a method that is not supported yet. Supported methods are 'GET', 'POST', 'PUT', 'PATCH' and 'DELETE'.`,
  POST_BODY_ID: `The field "id" cannot be set manually, not even on *Create* requests. This is autogenerated by the back-end when creating a new resource, and that reference should be used later on.`,
  POST_BODY_CREATED: `The field "created" cannot be set manually, not even on *Create* requests. This will be set at creation time by the back-end, and can be read subsequently.`,
  POST_BODY_UPDATED: `The field "updated" cannot be set manually, not even on *Create* requests. This will be set at creation time by the back-end, and can be read subsequently.`
};

export default ({ id, title, method, model }) => `
## ${title}

${method ? `<pre>${method}</pre>` : ""}

When a request produces an error, it will return the [HTTP Status Code](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes), a custom error code, a message and a link for more info:

\`\`\`js
try {
  const ${singular(model)} = await axios.post('/${model}', {
    firstname: 'John',
    lastname: 'Doe',
    created: '${new Date().toISOString()}'  // <- cannot set manually
  });
} catch (error) {
  // This logs the whole thing
  console.log(error.response);
}
\`\`\`

\`\`\`js
{
  status: 400,
  data: {
    error: {
      code: 'POST_BODY_CREATED',
      message: 'Cannot set "created" manually on the POST request body',
      details: 'https://faster.rest/${id}/docs#post-body-created'
    }
  }
}
\`\`\`

${Object.keys(errors(id))
  .map(
    name => `
### ${name}

${descriptions[name] || "No description yet..."}
`
  )
  .join("\n")}
`;
