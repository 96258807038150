import ReactGA from "react-ga";

// UGH. So disgustingly ugly. Why does REACT-ROUTER hijack hash changes??
export default () => {
  if (!window.location.hash.replace(/^#/, "")) {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  return null;
};
