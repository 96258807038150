import React from "react";
import styled from "styled-components";

import { Card } from "../../components";

const InputCard = styled(Card).attrs({
  as: "input",
  readOnly: true,
  type: "string"
})`
  padding: 15px 20px;
  border: none;
  height: 50px;
  font-size: inherit;
  background: #fff;
  box-shadow: ${p => p.theme.shadow};

  &:focus {
    border: none;
  }
`;

export default ({ id, model }) => (
  <InputCard value={`https://api.faster.rest/${id}`} />
);
