import React from "react";
import styled from "styled-components";
import axios from "axios";
import { useActions, useSelector } from "statux";

import { Button, Icon, Link, Flex } from "../../components";
import { hash, nanoid } from "../../utils";
import Toggle from "./Toggle";
import useLoading from "./useLoading";

const P = styled.p`
  margin: 10px 0;
`;

const KeyList = styled.div`
  pre {
    width: auto;
    margin: 0;
    padding: 6px 10px;
    display: block;
    line-height: 1.5;
  }

  ${Button} {
    font-size: 16px;
    vertical-align: middle;
  }
`;

const CreateKey = () => {
  const id = useSelector("api.id");
  const setKeys = useActions("api.keys");

  const addKey = async () => {
    const pass = nanoid();
    const hashed = await hash(pass);
    const key = { hash: hashed, short: pass.slice(0, 4), access: "read" };
    const prom = axios.post(`/keys/${id}/`, key);
    alert("Copy the key since it will never be displayed again:\n\n" + pass);
    const { data, error } = await prom;
    if (error) return alert(error.message);
    setKeys(data);
  };

  return (
    <P>
      <Link onClick={addKey}>
        <strong>
          <Icon name="add" /> Add Key
        </strong>
      </Link>
    </P>
  );
};

const UpdateKey = ({ short }) => {
  const id = useSelector("api.id");
  const setKeys = useActions("api.keys");
  const [loading, withLoading] = useLoading();
  const key = useSelector(state => {
    return state.api.keys.find(k => k.short === short) || {};
  });
  const onUpdate = withLoading(async access => {
    const newKey = { ...key, access };
    const { data, error } = await axios.patch(`/keys/${id}/${short}`, newKey);
    if (error) return alert(error.message);
    setKeys(data);
  });
  return (
    <Toggle
      loading={loading}
      options={["read", "write"]}
      value={key.access}
      onChange={onUpdate}
    />
  );
};

const DeleteKey = ({ short }) => {
  const id = useSelector("api.id");
  const setKeys = useActions("api.keys");
  const [loading, withLoading] = useLoading();
  const onDelete = withLoading(async () => {
    const { data, error } = await axios.delete(`/keys/${id}/${short}`);
    if (error) return alert(error.message);
    setKeys(data);
  });
  return (
    <Button delete onClick={onDelete} loading={loading}>
      <Icon name="delete_outline" />
    </Button>
  );
};

export default () => {
  const visibility = useSelector("api.visibility");
  const keys = useSelector("api.keys");

  if (visibility === "public") {
    return <P>The API is public, no key needed to access it</P>;
  }

  return (
    <KeyList>
      <P>Set the access keys to the API:</P>
      {keys.map(({ short, access }) => (
        <Flex center space key={short}>
          <pre>{short}...</pre>
          <div>
            <UpdateKey short={short} /> <DeleteKey short={short} />
          </div>
        </Flex>
      ))}
      <CreateKey />
    </KeyList>
  );
};
