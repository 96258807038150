import React from "react";
import Alert from "./Alert";
import Link from "./Link";

export default () => (
  <Alert>
    This is a very early dev build with many broken things.{" "}
    <Link to="https://twitter.com/faster_rest">Follow us on Twitter</Link> for
    updates ❤
  </Alert>
);
