import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector, useStore } from "statux";

import Toggle from "./Toggle";
import useLoading from "./useLoading";

export default () => {
  const id = useSelector("api.id");
  const [visibility, setVisibility] = useStore("api.visibility");
  const [loading, withLoading] = useLoading();

  const onChange = withLoading(async (visibility) => {
    const { data, error } = await axios.patch(`/${id}`, { visibility });
    if (error) return toast.error("Could not change the visibility");
    if (data.visibility !== visibility) {
      return toast.error("Could not change the visibility");
    }
    setVisibility(data.visibility);
  });

  return (
    <Toggle
      loading={loading}
      options={["public", "private"]}
      value={visibility}
      onChange={onChange}
    />
  );
};
