import React from "react";
import useHighlight from "../useHighlight";
import styled from "styled-components";

const Code = styled.div`
  margin: 0 -${p => p.theme.space}px;
  position: relative;
  overflow: hidden;
  height: 100%;

  @media (min-width: 900px) {
    margin: 0;
    border-radius: $radius;
  }

  pre {
    background: #fff;
    margin: 0;
    border-radius: ${p => p.theme.radius}px;
    width: 100%;
    max-height: 500px;
  }

  code {
    font-size: 0.9em;
  }
`;

const Status = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${p => p.theme.space / 4}px ${p => p.theme.space / 2}px;
  color: #fff;
  border-radius: 0 ${p => p.theme.radius}px 0 ${p => p.theme.radius}px;
  font-size: 0.9em;

  &[data-status^="2"] {
    background: ${p => p.theme.darkGreen};
  }
  &[data-status^="4"] {
    background: ${p => p.theme.darkRed};
  }
`;

const escape = text => {
  const p = document.createElement("p");
  p.textContent = text;
  return p.innerHTML;
};

export default ({ status, children, ...props }) => {
  const lang = Object.entries(props).find(pair => pair[1] === true)[0];
  useHighlight(lang, children);

  return (
    <Code>
      <Status data-status={status}>{status}</Status>
      <pre className={`language-${lang}`}>
        <code>{escape(children)}</code>
      </pre>
    </Code>
  );
};
