import React from "react";
import { singular } from "pluralize";
import styled from "styled-components";

const Type = styled.select`
  color: #333;
  background: none;
  font-size: 14px;
  font-family: monospace;
  font-weight: 400;
  position: absolute;
  right: 3px;
  top: 4px;
  min-width: 80px;
  width: auto;
  padding: 0 2px;
  border: 1px solid #ddd;
  line-height: 20px;
  height: 22px;
  border-radius: 22px;
  text-align: center;
`;

export default ({ value, onChange, models = [], ...props }) => (
  <Type
    className="type"
    value={value}
    onChange={e => onChange(e.target.value)}
    {...props}
  >
    <option value="string">string</option>
    <option value="number">number</option>
    <option value="bool">boolean</option>
    <option value="date">date</option>
    <option value="[string]">[strings]</option>
    <option value="[number]">[numbers]</option>
    <option value="[bool]">[booleans]</option>
    <option value="[date]">[dates]</option>
    {models.map(name => (
      <option key={name} value={`{${name}}`}>
        {`{${singular(name)}}`}
      </option>
    ))}
    {models.map(name => (
      <option key={name} value={`[${name}]`}>
        [{name}]
      </option>
    ))}
  </Type>
);
