import React from "react";
import styled from "styled-components";

const Icon = styled.i`
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: ${p => p["font-size"] || "18px"};
  height: 20px;
  line-height: 20px;
  width: 20px;
  padding: 0;
  text-align: center;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  vertical-align: text-bottom;
  display: inline-block;
  -moz-font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
  margin: -1px 0 0 0;

  .brand & {
    font-size: 16px;
  }
`;

export default ({ name, ...props }) => <Icon {...props}>{name}</Icon>;
