import React from "react";
import { useSelector, useStore } from "statux";
import useAsyncEffect from "use-async-effect";
import axios from "axios";
import { local } from "brownies";

import { client_id } from "../../config";
import { Button, Preview, Flex } from "../../components";

const withUser = Comp => () => {
  const user = useSelector("user");
  if (!user) {
    window.location = `https://github.com/login/oauth/authorize?client_id=${client_id}&scope=user:email`;
    return null;
  }
  return <Comp />;
};

export default withUser(() => {
  const id = useSelector("user.id");
  const name = useSelector("user.name");
  const [apis, setApis] = useStore("user.apis");

  useAsyncEffect(async isMounted => {
    try {
      const res = await axios.get(`/users/${id}`);
      setApis(res.data.apis);
    } catch (error) {
      alert("Error reading your data. Please try logging out and in again");
      throw error;
    }
  }, []);

  return (
    <>
      <section>
        <h1>User page</h1>
        <p>Hi {name}. These are fake APIs for now:</p>
        <Flex one three-900 four-1200>
          {apis?.map(api => (
            <Preview
              to={api.id}
              name={api.id}
              count={api.requests}
              label="req/day"
            />
          ))}
        </Flex>

        <br />

        <Button
          delete
          onClick={e => {
            delete local.token;
            window.location = "/";
          }}
        >
          LOGOUT
        </Button>
      </section>
    </>
  );
});
