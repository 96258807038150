import React from "react";
import { NavLink } from "react-router-dom";

export default ({ to, ...props }) => {
  if (props.htmlFor) {
    return <label {...props} />;
  }

  if (!to) {
    if (props.onClick) {
      // eslint-disable-next-line
      return <a {...props} />;
    }
    return props.children;
  }

  if (/^#/.test(to)) {
    // eslint-disable-next-line
    return <a href={to} {...props} />;
  }

  if (/^https?:\/\//.test(to)) {
    // eslint-disable-next-line
    return <a href={to} target="_blank" rel="noopener noreferrer" {...props} />;
  }

  return <NavLink to={to} {...props} />;
};
