import React from "react";

import styled from "styled-components";

const Link = styled.a`
  float: right;
  display: inline;
  border: 1px solid #9084fb;
  padding: 0 8px;
  border-radius: 4px;
  line-height: 22px;
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: monospace;
  font-weight: normal;

  &:hover {
    color: #fff;
    background: #9084fb;
  }
`;

export default ({ repo }) => {
  const href = `https://github.com/${repo}`;
  return (
    <Link href={href} target="_blank" rel="noopener noreferrer">
      Github
    </Link>
  );
};
