import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Card from "./Card";
import Flex from "./Flex";

export const Name = styled.div`
  font-weight: bold;
  line-height: 1;
  margin-bottom: 5px;
`;

export const Count = styled.div`
  font-size: 2em;
  line-height: 1.2;
  code {
    padding: 0.15em 0.3em;
  }
`;

export const Type = styled.div`
  color: #aaa;
  font-weight: bold;
  &:after {
    content: "→";
    margin-left: 4px;
    font-size: 0.8em;
  }
`;

export default ({ to, name, count = 0, label }) => (
  <Card as={Link} to={to}>
    <Name>{name}</Name>
    <Flex tight left base>
      <Count>{count}</Count>
      {label && <Type>{label}</Type>}
    </Flex>
  </Card>
);
