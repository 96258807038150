import React from "react";
import { useParams } from "react-router";
import useAsyncEffect from "use-async-effect";
import { useStore } from "statux";
import axios from "axios";

import { Loading } from "../components";
import { create } from "../utils";

export default Component => () => {
  const { id = "demo", ...params } = useParams();
  const [api, setApi] = useStore("api");

  useAsyncEffect(
    async isMounted => {
      // Already loaded or it's currently loading
      if (api.loading) return;

      try {
        const { data } = await axios.get(`/${id}`);
        if (!isMounted()) return;
        setApi(data);
      } catch (error) {
        if (!isMounted()) return;
        const status = error.response?.status;
        if (status === 404) {
          return setApi(create(id));
        }
        setApi({ error: error.message });
      }
    },
    [id]
  );

  if (api.error) return <p>{api.error}</p>;
  if (!api.schema) return <Loading />;
  if (id !== api.id) return <Loading />;
  return <Component id={id} {...params} />;
};
