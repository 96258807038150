import { singular } from "pluralize";
import buildFields from "./buildFields";

export default ({ id, name, method, title, entry }) => `
## ${title}

\`\`\`
${method}
\`\`\`

Retrieve a single item of ${name} from the database. Returns a JSON that is an Object:

\`\`\`js
const id = '${entry.id}';
const ${singular(name)} = await axios.get(\`/${name}/\${id}\`);
console.log(${singular(name)});
\`\`\`

\`\`\`js
${buildFields(entry)}
\`\`\`

If the ${singular(name)} does not exist, it will **throw a 404**:

\`\`\`js
const id = 'non-existing-id';
try {
  const ${singular(name)} = await axios.get(\`/${name}/\${id}\`);
} catch (error) {
  console.log(error);
}
\`\`\`

\`\`\`js
{
  "error": {
    "code": "GET_NOT_FOUND",
    "message": "The item '/${name}/non-existing-id' was not found",
    "more_info": "https://faster.rest/${id}/errors/GET_NOT_FOUND"
  }
}
\`\`\`
`;
