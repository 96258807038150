import React from "react";
import styled from "styled-components";
import { Button } from "../../components";

const Missing = styled.div`
  text-align: center;
  margin: 30px 0 80px;
`;

export default ({ onClick }) => (
  <Missing>
    <p>There are no models yet</p>
    <Button primary onClick={onClick}>
      Create Models
    </Button>
  </Missing>
);
