import React, { useState } from "react";
import { singular } from "pluralize";
import slug from "@sindresorhus/slugify";
import { useSelector } from "statux";
import styled from "styled-components";

export const Menu = styled.div`
  position: sticky;
  top: ${p => p.theme.space * 4}px;
  bottom: ${p => p.theme.space}px;
  margin-left: -10px;
  max-height: calc(100vh - ${p => p.theme.space * 4}px);
  overflow-y: auto;

  h2 {
    margin: 0;
    padding: 0 0 10px 10px;
    @media (min-width: 900px) {
      line-height: 30px;
      padding: 0 0 10px 10px;
    }
  }

  a {
    display: block;
    border-radius: ${p => p.theme.radius}px;
    line-height: 20px;
    color: #333;
    cursor: pointer;
    padding: 5px 10px;
    flex-grow: 1;

    @media (min-width: 900px) {
      padding: 10px 15px;
      margin: 3px 0;
    }

    &:hover {
      background: #fff;
      box-shadow: $lightShadow;
    }
  }
`;

const SectionHead = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const SectionBody = styled.div`
  padding-left: 50px;
`;

const Arrow = styled.span`
  height: 40px;
  line-height: 40px;
  width: 40px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transform-origin: center center;
  transform: rotate(${p => (p.rotate ? 90 : 0)}deg);
  transition: all 0.3s ease;

  &:hover {
    background: white;
  }

  & + a {
    margin-left: 10px;
  }
`;

const capitalize = str => str[0].toUpperCase() + str.slice(1);

const Section = ({ name, children }) => {
  const [show, setShow] = useState(name === "Introduction");
  return (
    <div>
      <SectionHead>
        <Arrow onClick={e => setShow(!show)} rotate={show}>
          ►
        </Arrow>
        <Link title={capitalize(name)} />
      </SectionHead>
      <SectionBody>{show ? children : null}</SectionBody>
    </div>
  );
};

const Link = ({ title }) => <a href={`#${slug(title)}`}>{title}</a>;

export default () => {
  const models = useSelector(state => Object.keys(state.api.schema));
  return (
    <Menu>
      <Section name="Introduction">
        <Link title="Getting started" />
        <Link title="Auth" />
        <Link title="Error Handling" />
      </Section>
      {models.map(name => (
        <Section key={name} name={name}>
          <Link title={`Find all ${name}`} />
          <Link title={`Find a ${singular(name)}`} />
          <Link title={`Create new ${singular(name)}`} />
          <Link title={`Edit a ${singular(name)} field`} />
          <Link title={`Replace a ${singular(name)}`} />
          <Link title={`Delete a ${singular(name)}`} />
        </Section>
      ))}
    </Menu>
  );
};
