import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "statux";

import { Flex, Icon, Preview } from "../../components";
import { withApi } from "../../utils";

import Api from "./Api";
import Settings from "./Settings";
import Edit from "./Edit";
import NoModel from "./NoModel";

const apiMethodCount = (schema = {}) => Object.keys(schema).length * 6;

const DisplaySchema = () => {
  const id = useSelector("api.id");
  const schema = useSelector("api.schema");
  const data = useSelector("api.data");
  const [edit, setEdit] = useState(false);
  const models = Object.keys(schema);

  if (edit) {
    return <Edit onSave={e => setEdit(false)} />;
  }

  if (!models.length) {
    return <NoModel onClick={e => setEdit(true)} />;
  }

  return (
    <Flex one two-600 three-800>
      <Flex flex="full" space>
        <h2 id="models">Database</h2>
      </Flex>
      {models.map(name => (
        <Preview
          to={`/${id}/data/${name}`}
          key={name}
          name={name}
          count={data[name].length}
          label="items"
        />
      ))}
    </Flex>
  );
};

export default withApi(() => {
  const { id, visibility, requests, schema } = useSelector("api");
  return (
    <section>
      <Flex one three-800>
        <h1 flex="full">
          <Link to={`/${id}`}>{id}</Link>
        </h1>
        <Api flex="two-third-800" id={id} />
        <Settings to={`/${id}/settings`}>
          <Flex space>
            <div>
              <Icon name="build" /> Settings
            </div>
            <div className="label">{visibility}</div>
          </Flex>
        </Settings>

        <Preview
          to={`/${id}/docs`}
          name="API Documentation"
          count={apiMethodCount(schema)}
          label="endpoints"
        />

        <Preview
          to={`/${id}/logs`}
          name="Request Logs"
          count={requests.total}
          label="calls in 24h"
        />

        <Preview
          to={`/${id}/test`}
          name="Test API"
          count={Object.keys(schema).length}
          label="models"
        />
      </Flex>

      <div style={{ marginTop: "60px" }} />

      <DisplaySchema />
    </section>
  );
});
