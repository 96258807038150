import React from "react";
import styled from "styled-components";

import { withApi } from "../../utils";
import { Card, Flex, Link } from "../../components";
import Visibility from "./Visibility";
import Keys from "./Keys";
import DangerButtons from "./DangerButtons";
import Download from "./Download";

const P = styled.p`
  margin: 10px 0;
`;

export default withApi(() => (
  <section>
    <Flex one two-600>
      <h1 flex="full">Settings</h1>
      <Flex>
        <Card>
          <Flex space center>
            <h2>Visibility</h2>
            <Visibility />
          </Flex>
        </Card>
        <Card>
          <h2>Access Keys</h2>
          <Keys />
        </Card>
        <Card>
          <h2>Danger Zone</h2>
          <P>These operation cannot be reversed:</P>
          <DangerButtons />
        </Card>
      </Flex>
      <Flex>
        <Card>
          <h2>Download</h2>
          <P>
            You can download the API schema formatted with the{" "}
            <Link to="https://swagger.io/docs/specification/about/">
              <strong>Open API Specification 3.0.0</strong>
            </Link>
            :
          </P>
          <Download />
        </Card>
      </Flex>
    </Flex>
  </section>
));
