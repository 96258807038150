import styled from "styled-components";

export default styled.label`
  display: flex;
  margin-bottom: -1px;
  align-items: stretch;

  & > div {
    padding: 0 10px;
    border-radius: 0;
    background: #eee;
    line-height: 38px;
    flex-grow: 1;
    border: 1px solid #ccc;
    border-right: none;
    white-space: nowrap;
  }

  input {
    flex-basis: 70%;
    line-height: 38px;
    height: 40px;
    border-radius: 0;
    &:focus {
      z-index: 1;
    }
  }

  .react-select__control {
    height: 40;
    min-height: 40;
    border-radius: 0;
    border: 1px solid #ccc;
    box-shadow: none;
    &:hover,
    &:focus,
    &:active {
      border: 1px solid #ccc;
      box-shadow: none;
    }
  }

  .react-select__value-container {
    padding: 4px 0 0 4px;
    position: relative;
  }

  .react-select__placeholder {
    display: none;
  }

  .react-select__multi-value__label {
    padding: 0;
  }

  input {
    transition: none;
  }

  .react-select__input input {
    height: 30px;
  }

  .react-select__value-container > * {
    margin: 0 4px 4px 0;
    padding: 0;
  }

  &:first-child {
    div {
      border-radius: 4px 0 0 0;
    }
    input {
      border-radius: 0 4px 0 0;
    }
  }

  &:last-child {
    div {
      border-radius: 0 0 0 4px;
    }
    input {
      border-radius: 0 0 4px 0;
    }
  }
`;
