import React, { useState } from "react";
import styled, { css } from "styled-components";

import useApi from "../../useApi";
import { Button, Card, Flex, Loading } from "../../components";

const type = status => {
  if (status === 200) return "ok";
  if (status >= 201 && status <= 299) {
    return "warn";
  }
  return "error";
};

const row = css`
  display: flex;
  justify-content: space-between;
  background: #eef5ff;
  padding: 0.5em 0.8em;
  align-items: center;
  max-width: none;
  margin: 0 -20px 10px;
  @media (min-width: 600px) {
    margin: 0 0 10px;
    border-radius: 0.2em;
  }
`;

const Full = styled.a`
  ${row}
`;

const colors = {
  error: "f00",
  success: "5749d8"
};

const Label = styled.div`
  font-size: 0.6em;
  margin-left: 1em;
  line-height: 1;
  display: inline-block;
  font-size: 0.9rem;
  margin-left: 0;
  font-family: monospace;
  min-width: 60px;
  text-align: right;
  color: #${({ type }) => colors[type] || colors.success};
`;

const Method = styled(Label)`
  text-align: left;
  min-width: 70px;
`;

const Path = styled(Method)`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Status = styled(Label)`
  min-width: 50px;
`;

const FullButton = styled(Button)`
  ${row}
  color: #${colors.success};
  justify-content: center;
`;

const Selected = ({ id, method, path, status, time, size }) => (
  <Card>
    <Flex two top>
      <div>
        <h2>Method</h2>
        <p>{method}</p>
      </div>
      <div>
        <h2>Path</h2>
        <p>{path}</p>
      </div>
      <div>
        <h2>Status Code</h2>
        <p>{status}</p>
      </div>
      <div>
        <h2>Timing</h2>
        <p>{time}ms</p>
      </div>
      <div>
        <h2>Size</h2>
        <p>{size}kb</p>
      </div>
    </Flex>
  </Card>
);

export default ({ id = "demo" }) => {
  const { schema, requests = [], error } = useApi(id);
  const [selected, setSelected] = useState({});
  if (error) return <p>{error}</p>;
  if (!schema) return <Loading />;
  return (
    <section className="Requests">
      <Flex one two-600>
        <h1 flex="full">API Requests</h1>
        <Card>
          {requests.total === 0
            ? "No requests made yet..."
            : requests.recent.map(call => (
                <Full
                  key={call.id}
                  selected={call.id === selected.id}
                  onClick={e =>
                    setSelected(selected && selected.id === call.id ? {} : call)
                  }
                >
                  <Method>{call.method}</Method>
                  <Path>{call.path}</Path>
                  <Label>{call.time}ms</Label>
                  <Label>{call.size}kb</Label>
                  <Status type={type(call.status)}>{call.status}</Status>
                </Full>
              ))}
          {requests.total !== requests.recent.length && (
            <FullButton modal="payment_modal">+ Load More (upgrade)</FullButton>
          )}
        </Card>
        <Flex none full-600>
          {selected.method ? (
            <Selected {...selected} />
          ) : (
            <Card>Pick one on the left for more information</Card>
          )}
        </Flex>
      </Flex>
    </section>
  );
};
