// import React, { useState } from "react";
// import styled from "styled-components";
// import forn from "forn";
//
// import Button from "./Button";
//
// const Form = styled.form`
//   position: relative;
//   margin-top: 10px;
//
//   button {
//     position: absolute;
//     top: 0;
//     right: 0;
//     margin: 0;
//     border-radius: 0 0.2em 0.2em 0;
//   }
// `;

// export default () => (
//   <Form
//     action="https://francisco.us9.list-manage.com/subscribe/post?u=8f634b4eeb27e1bb51438f11f&amp;id=d740a251b6"
//     method="post"
//   >
//     <input type="email" name="EMAIL" placeholder="Email for updates" required />
//     <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
//       <input
//         type="text"
//         name="b_8f634b4eeb27e1bb51438f11f_d740a251b6"
//         tabIndex="-1"
//         value=""
//       />
//     </div>
//     <Button primary>Subscribe</Button>
//   </Form>
// );

export default () => null;
