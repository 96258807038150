import React from "react";
import { singular } from "pluralize";
import slug from "@sindresorhus/slugify";
import { useSelector } from "statux";

import { nanoid, withApi } from "../../utils";
import { Flex } from "../../components";
import { Title, SubTitle, Markdown } from "./styled";

import Toc from "./Toc";
import gettingStarted from "./gettingStarted";
import auth from "./auth";
import errorHandling from "./errorHandling";
import findAll from "./findAll";
import findOne from "./findOne";
import createOne from "./createOne";
import updateOne from "./updateOne";
import replaceOne from "./replaceOne";
import deleteOne from "./deleteOne";

const capitalize = str => str[0].toUpperCase() + str.slice(1);

// Binds the id, model, and some other data
const Section = ({ src, ...props }) => {
  const id = useSelector("api.id");
  const model = useSelector(
    state => Object.keys(state.schema || {})?.[0] || "users"
  );
  const allProps = { id, model, ...props };
  return <Markdown>{src(allProps)}</Markdown>;
};

const Model = ({ name, fields }) => {
  const data = useSelector(`api.data.${name}`);
  const entry = data[0] || {
    id: nanoid(),
    created: new Date().toISOString(),
    updated: new Date().toISOString()
  };
  return (
    <>
      <SubTitle id={slug(name)}>{capitalize(name)}</SubTitle>
      <Section
        name={name}
        entry={entry}
        title={`Find all ${name}`}
        method={`GET /${name}`}
        src={findAll}
      />

      <Section
        name={name}
        entry={entry}
        title={`Find a ${singular(name)}`}
        method={`GET /${name}/:id`}
        src={findOne}
      />

      <Section
        name={name}
        entry={entry}
        fields={fields}
        title={`Create new ${singular(name)}`}
        method={`POST /${name}`}
        src={createOne}
      />

      <Section
        name={name}
        entry={entry}
        fields={fields}
        title={`Edit a ${singular(name)} field`}
        method={`PATCH /${name}/:id`}
        src={updateOne}
      />

      <Section
        name={name}
        entry={entry}
        fields={fields}
        title={`Replace a ${singular(name)}`}
        method={`PUT /${name}/:id`}
        src={replaceOne}
      />

      <Section
        name={name}
        entry={entry}
        fields={fields}
        title={`Delete a ${singular(name)}`}
        method={`DELETE /${name}/:id`}
        src={deleteOne}
      />
    </>
  );
};

const Documentation = () => {
  const schema = useSelector("api.schema");
  return (
    <>
      <Title>Documentation</Title>

      <Section title="Getting started" src={gettingStarted} />
      <Section title="Auth" src={auth} />
      <Section title="Error handling" src={errorHandling} />

      {Object.entries(schema).map(([name, fields]) => {
        return <Model name={name} fields={fields} />;
      })}
    </>
  );
};

export default withApi(() => (
  <Flex as="section" one four-900>
    <Toc />
    <Documentation flex="full three-fourth-900" />
  </Flex>
));
