import React from "react";
import axios from "axios";
import { useSelector, useStore } from "statux";

import { Button, Flex } from "../../components";
import { withApi } from "../../utils";
import Table from "./Table";
import Insert from "./Insert";
import NoData from "./NoData";

export default withApi(({ id, model }) => {
  const { schema, data } = useSelector("api");
  const [api, setApi] = useStore("api");

  const onSubmit = async body => {
    const { data: item } = await axios.post(`/${id}/${model}/`, body);
    setApi({
      ...api,
      data: { ...data, [model]: [...data[model], item] }
    });
  };

  return (
    <section className="Data">
      <Insert api={id} model={model} schema={schema} onSubmit={onSubmit} />
      <Flex one>
        <Flex flex="full">
          <h1>
            Database for <i>{model}</i>
          </h1>

          <Button flex="nogrow" icon="add" htmlFor="id-insert">
            Add
          </Button>
        </Flex>

        {data[model].length ? (
          <Table
            id={id}
            model={model}
            flex="full"
            name={model}
            url="/api/users"
            schema={schema}
            data={data[model]}
          />
        ) : (
          <NoData name={model} htmlFor="id-insert" />
        )}
      </Flex>
    </section>
  );
});
