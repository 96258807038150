import nid from "./nanoid.js";
import errorGenerator from "./errors.js";

const now = () => new Date().toISOString();
const create = ({ id = nid(), created = now(), updated = now(), ...body }) => ({
  id,
  ...body,
  created,
  updated
});
const isPlain = (str = "") => /^[a-zA-Z0-9]+$/.test(str);

const validateBody = (body, schema, errors) => {
  const valid = schema.map(sch => sch.name);
  for (let key in body) {
    if (!valid.includes(key)) {
      const subValid = schema
        .filter(s => s.access === "write")
        .map(s => s.name);
      return errors.KEY_NOT_FOUND({ key, valid: subValid });
    }
  }
  for (const { name: key, type } of schema) {
    // We don't care about empty keys
    if (typeof body[key] === "undefined") continue;

    // Basic types
    if (["string", "number", "boolean"].includes(type)) {
      const found = typeof body[key];
      if (found !== type) {
        return errors.INVALID_TYPE({ key, type, found, value: body[key] });
      }
    }
  }
};

export default async (db, { method, model, id, body }, save = () => {}) => {
  const { schema, data } = db;

  const errors = errorGenerator(db.id);

  // The model is required for any request
  if (!isPlain(model)) {
    return errors.COLLECTION_NOT_FOUND({ model });
  }
  // This is VERY likely an attack; consider reporting it for LOLs
  if (id && !isPlain(model)) {
    return errors.NOT_FOUND({ thing: `/${model}/${id}` });
  }

  if (!Object.keys(schema).includes(model)) {
    return errors.COLLECTION_NOT_FOUND({ model });
  }
  const table = data[model];

  if (["post", "put", "patch"].includes(method)) {
    if (!body) return errors.EMPTY_BODY({ method });
    if (typeof body !== "object") return errors.BODY_NO_OBJECT();
  } else {
    if (body) return errors.NO_BODY({ method });
  }

  // It can only validate the basics, since some validations are method-specific
  if (body) {
    const error = validateBody(body, schema[model], errors);
    if (error) return error;
  }

  if (method === "get") {
    if (!id) return table;

    const item = table.find(item => id === item.id);
    if (!item) return errors.GET_NOT_FOUND({ model, id });
    return item;
  }

  if (method === "post") {
    if (id) return errors.POST_URL_ID({ model, id });
    if (body.id) return errors.POST_BODY_ID();
    if (body.created) return errors.POST_BODY_CREATED();
    if (body.updated) return errors.POST_BODY_UPDATED();

    const instance = create(body);
    db.data[model].push(instance);
    await save(db);
    return instance;
  }

  if (method === "put") {
    if (!id) return errors.PUT_URL_NO_ID({ model });
    if (body.id && id !== body.id) {
      return errors.PUT_ID_MISMATCH({ model, id, id2: body.id });
    }

    const item = table.find(item => id === item.id);
    if (!item) return errors.PUT_NOT_FOUND({ model, id });
    // NOTE: REPLACES the whole body, since it's 'PUT'
    const updated = {
      id: item.id,
      ...body,
      created: item.created,
      updated: now()
    };
    table.forEach((item, i) => {
      if (id !== item.id) return;
      db.data[model][i] = updated;
    });
    await save(db);
    return updated;
  }

  if (method === "patch") {
    if (!id) return errors.PATCH_URL_NO_ID({ model });
    if (body.id && id !== body.id) {
      return errors.PATCH_ID_MISMATCH({ model, id, id2: body.id });
    }

    const item = table.find(item => id === item.id);
    if (!item) return errors.PATCH_NOT_FOUND({ model, id });
    // NOTE: REPLACES only the parts added, since it's 'PATCH'
    const updated = {
      id: item.id,
      ...item,
      ...body,
      created: item.created,
      updated: now()
    };
    table.forEach((item, i) => {
      if (id !== item.id) return;
      db.data[model][i] = updated;
    });
    await save(db);
    return updated;
  }

  if (method === "delete") {
    if (!id) return errors.DELETE_URL_NO_ID({ model });

    const item = table.find(item => id === item.id);
    if (!item) return errors.DELETE_NOT_FOUND({ model, id });

    data[model] = data[model].filter(data => data.id !== id);
    await save(db);
    return {};
  }

  return errors.METHOD_NOT_AVAILABLE({ method });
};
