import styled from "styled-components";

export default styled.span`
  content: "";
  display: inline-block;
  width: 20px;
  ${p =>
    p.grow &&
    `
    flex-grow: 1;
  `}
`;
