export default ({ id, title, method }) => `
## ${title}

The base URL for this API is:

\`\`\`
https://api.faster.rest/${id}
\`\`\`

All your API paths are relative to this URL. These docs use the popular [axios library](https://github.com/axios/axios) for making HTTP requests but you can use whichever you prefer:

\`\`\`js
import axios from 'axios';
axios.defaults.baseURL = 'https://api.faster.rest/${id}';
\`\`\`

The keyword \`await\` can only be used within an \`async\` context, so all of the code here assumes that you are in an \`async\` context:

\`\`\`js
import axios from 'axios';
axios.defaults.baseURL = 'https://api.faster.rest/${id}';

(async () => {
  // This is inside an async context
  // ...
})();

const login = async () => {
  // This is inside an async context
  // ...
};
\`\`\`
`;
