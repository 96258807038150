export default (id, user) => ({
  id,
  visibility: "public",
  keys: [],
  settings: {
    users: [user],
    keys: []
  },
  requests: {
    total: 0,
    recent: []
  },
  schema: {},
  data: {}
});
